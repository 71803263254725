import { Component, OnInit } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  constructor(private menuservices: MenuService) { }

  ngOnInit(): void {
  }

  mostrarMenu(){
    if(!this.menuservices.MenuVisible){
      this.menuservices.MenuVisible = true;
    }
    else if(this.menuservices.MenuVisible){
      this.menuservices.MenuVisible = false
    }
    
  }

}
