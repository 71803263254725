
<nav id="sidebar" class='sidebar'>
  <div class="sidebar-content">
    <perfect-scrollbar>

      <div class='sidebar-close'>
        <!-- <a (click)='ocultarMenu()'>
          <i class="fas fa-times fa-2x"></i>
        </a> -->
        
      </div>

      <div class='sidebar-profile'>
        <div class="user-pic">
          <img class="img-responsive img-rounded" src="assets/img/user.jpg" alt="User picture">
        </div>
        <div class="user-info">
          <span class="user-name">
            <strong>{{Configuracion.nombre}}</strong>
          </span>
          <span class="user-role">{{Configuracion.perfil}}</span>
          <span class="user-status">
            <i class="fa fa-circle"></i>
            <span>Online</span>
          </span>
        </div>
      </div>
      
      <div class="sidebar-menu">
        <ul>
            <span class="header-menu">Punto Admin</span>
            <li *ngFor="let modulo of Configuracion.modulos" [ngClass]="{'active': modulo.Activo}" class="sidebar-dropdown">
                <a (click)='cambiarEstado(modulo)'>
                    <i class="{{modulo.imagen}}"></i>
                    <span>{{modulo.modulo}}</span>
                </a>
                <div class="sidebar-submenu" [@slide]="obtenerEstado(modulo)">
                    <ul>
                    <li *ngFor="let subModulos of modulo.subModulos">
                        <a (click)='redireccionar(subModulos.ruta)'> {{subModulos.submodulo}} 
                        </a>
                    </li>
                    </ul>
                </div>
            </li>
            <span class="header-menu">Sesion</span>
            <li >
              <a (click)='cerrarSesion()'>
                <i class="fas fa-door-open"></i>
                <span>Cerrar Sesion</span>
              </a>
            </li>
            <li>
              <a *ngIf="turnoServices.turnoIniciado" (click)='redireccionar("terminarTurno")'>
                <i class="fas fa-sign-out-alt"></i>
                  <span>Terminar Turno</span>
              </a>
              <a *ngIf="!turnoServices.turnoIniciado" (click)='redireccionar("iniciarTurno")'>
                <i class="fas fa-sign-out-alt"></i>
                  <span >Iniciar Turno</span>
              </a>
              
            </li>
        </ul>
      </div>
    </perfect-scrollbar>
  </div>
  
  
</nav>
