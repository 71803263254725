import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { ApiauthService } from 'src/app/services/apiauth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  user = {
    email:'',
    password:''
  }

  constructor(
    private router: Router,
    public apiauthService: ApiauthService
  ) { 
      if(this.apiauthService.usuarioData){
        this.router.navigate(['/home']);
      }
   }

  ngOnInit(): void {
  }

  SignIn(){

    Swal.fire({
      title: "Iniciando Sesion ... ",
      text: "",
      timer: 15000,
      showConfirmButton: false,
      allowOutsideClick: false,
      willOpen: () => {
       
        Swal.showLoading()
        
      }
        
    });

    this.apiauthService.login(this.user.email, this.user.password).subscribe(response =>{
      if(response.status === 1){
        Swal.fire({
          title: "Listo!",
          text: "Sesion Iniciada correctamente",
          icon: "success",
          timer: 500,
        });
        this.router.navigate(['/home']);
      }else{
        console.log('error');
      }
    })
  }

}
