import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { ConfiguracionResponse } from '../models/Response/ConfiguracionResponse';
import { Usuario } from '../models/usuario';
import { map } from 'rxjs/operators'
import Settings from '../settings.json';

const httpOption = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
const httpbody = {
  "UserName":"",
    "Password" : ""
}

@Injectable({
  providedIn: 'root'
})
export class ApiauthService {

  url: string = Settings.ruta_api.concat("/Login/Autentificacion");

  private usuarioSubjet: BehaviorSubject<Usuario>;

  public usuario: Observable<Usuario>;

  public get usuarioData(): Usuario{
    return this.usuarioSubjet.value;
  }

  constructor( private _http: HttpClient) {  
    this.usuarioSubjet = new BehaviorSubject<Usuario>(JSON.parse(localStorage.getItem('usuario')));
    this.usuario = this.usuarioSubjet.asObservable();
  }

  public login(email: string, password: string): Observable<ConfiguracionResponse>{
    httpbody.UserName = email;
    httpbody.Password = password;
    return this._http.post<ConfiguracionResponse>(this.url, httpbody, httpOption).pipe(
      map(res => {
        if(res.status === 1){
          const usuario: Usuario = res.data;
          localStorage.setItem('usuario',JSON.stringify(usuario));
          this.usuarioSubjet.next(usuario);
        }
        return res;
      })
    );
  }

  logout(){
    localStorage.removeItem('usuario');
    this.usuarioSubjet.next(null);
  }
}
