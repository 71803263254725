import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  user = {
    email:'',
    password:''
  }

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  SignUp(){
    if(this.user.email=="admin")
    {
      this.router.navigate(['/principal']);
    }
    else
    {
      console.log("incorrecto");
    }
  }

}
