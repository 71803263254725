import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IniciarTurno } from 'src/app/models/Request/IniciarTurno';
import { GenericResponse } from 'src/app/models/Response/GenericResponse';
import Settings from '../../settings.json';
import { map } from 'rxjs/operators';
import {TerminarTurno } from 'src/app/models/Request/TerminarTurno';

const httpOption = {
  headers: new HttpHeaders({
    'Content-Type':'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class TurnosService {

  controller = "";

  private turnoSubject: BehaviorSubject<IniciarTurno>;
  public turnoIniciado:boolean = false;
  
  constructor(private _http:HttpClient) { }

  iniciarTurno(iniciarTurno:IniciarTurno): Observable<GenericResponse>{
    this.controller = '/Ventas/IniciarTurno';

    return this._http.post<GenericResponse>(Settings.ruta_api.concat(this.controller), iniciarTurno, httpOption).pipe(
      map(res => {
          localStorage.setItem('configuracion', JSON.stringify(res.data));
          return res;
        }
    ));
  }

  consultarTurno(): Observable<GenericResponse>{
    this.controller = '/Ventas/ConsultarTurno';

    return this._http.get<GenericResponse>(Settings.ruta_api.concat(this.controller));
  }

  terminarTurno(terminarTurno:TerminarTurno): Observable<GenericResponse>{
    this.controller = '/Ventas/TerminarTurno';

    return this._http.post<GenericResponse>(Settings.ruta_api.concat(this.controller), terminarTurno, httpOption).pipe(
      map(res => {
          localStorage.setItem('configuracion', JSON.stringify(res.data));
          return res;
        }
    ));
  }
}
