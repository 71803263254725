import { Component, HostListener, OnInit, ElementRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MenuService } from '../../services/menu.service';
import { ArrayType } from '@angular/compiler';
import { ConfiguracionResponse } from 'src/app/models/Response/ConfiguracionResponse';
import { Router } from '@angular/router';
import { ApiauthService } from 'src/app/services/apiauth.service';
import { TurnosService } from 'src/app/services/Ventas/turnos.service';

// import { MenusService } from './menus.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(200))
    ])
  ]
})
export class MenuComponent implements OnInit {
  public Configuracion;

  

  constructor(private router: Router, public menuservices: MenuService, public apiauthservice:ApiauthService, public turnoServices:TurnosService) {

    if(JSON.parse(localStorage.getItem('usuario')).configuracion == null)
    {
      this.cerrarSesion();
    }

    this.Configuracion = JSON.parse(localStorage.getItem('usuario')).configuracion;

    turnoServices.consultarTurno().subscribe(response => {

      if(response.data == null){
        turnoServices.turnoIniciado = false;
      }
      else{
        turnoServices.turnoIniciado = true;
      }

    });

   }

   ngOnInit() {
    
  }

  cambiarEstado(currentMenu:any) {
    
      this.Configuracion.modulos.forEach(element => {
        if (element === currentMenu) {
          currentMenu.activo = !currentMenu.activo;
        } else {
          element.activo = false;
        }
      });
    
  }

  obtenerEstado(currentMenu:any) {

    if (currentMenu.activo) {
      return 'down';
    } else {
      return 'up';
    }
  }

  cerrarSesion(){
    this.apiauthservice.logout();
    this.router.navigate(['signin']);
  }

  redireccionar(ruta:string){
    this.menuservices.MenuVisible = false;
    this.router.navigate([ruta]);
  }

  ocultarMenu(){
    this.menuservices.MenuVisible = false;
  }



}
