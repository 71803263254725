import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiauthService } from 'src/app/services/apiauth.service';

@Component({
  selector: 'app-cargando',
  templateUrl: './cargando.component.html',
  styleUrls: ['./cargando.component.scss']
})
export class CargandoComponent implements OnInit {

  constructor(private apiauthservices:ApiauthService,
              private router:Router) {
    console.log(apiauthservices.usuario);
    if (apiauthservices.usuario == null){
      this.router.navigate(['signin']);
    }
   }

  ngOnInit(): void {
  }

}
