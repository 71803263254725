import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from './models/usuario';
import { ApiauthService } from './services/apiauth.service';
import { MenuService } from './services/menu.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'AdminTiveFront';

  //Autentificado = JSON.parse(localStorage.getItem('usuario')) == null ? false : true;
  //develop
  usuario: Usuario;

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    console.log('size changed.', event.currentTarget.outerWidth);
    if(event.currentTarget.outerWidth > 1000){
      this.menuservices.Movil = false;
      this.menuservices.MenuVisible = false;
    }
    else if(event.currentTarget.outerWidth<=1000){
      this.menuservices.Movil = true;
    }
  }

  constructor(public apiauthService: ApiauthService, 
              private router: Router,
              public menuservices:MenuService
    ){
      this.apiauthService.usuario.subscribe(res => {
      this.usuario = res;

      });
      console.log(screen.width);
      if(screen.width > 1000){
        this.menuservices.Movil = false;
      }
      else if(screen.width <= 1000){
        this.menuservices.Movil = true;
      }
  }
}
