import { Component, OnInit } from '@angular/core';
import { SucursalesService } from 'src/app/services/Administracion/sucursales.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TurnosService } from 'src/app/services/Ventas/turnos.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';


@Component({
  selector: 'app-iniciarturno',
  templateUrl: './iniciarturno.component.html',
  styleUrls: ['./iniciarturno.component.scss']
})
export class IniciarturnoComponent implements OnInit {

  public Configuracion = JSON.parse(localStorage.getItem('usuario')).configuracion;
  public Turno = JSON.parse(localStorage.getItem('turno'));
  public seleccionado: string ;

  public IniciarForm = this.formBuilder.group({
    empleadoId: [''],
    sucursalId: ['', Validators.required],
    montoInicial: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder, private turnoServices: TurnosService, private router:Router) { 

      if(JSON.parse(localStorage.getItem('turno')) !== null){
        this.router.navigate(['home']);
      }

      
      this.seleccionado = this.Configuracion.sucursales[0].sucursalId;
      console.log(this.Configuracion);
  }

  ngOnInit(): void {
  }

  agregarTurno(){
    this.IniciarForm.value.empleadoId = this.Configuracion.empleadoId;
    this.IniciarForm.value.sucursalId = Number(this.IniciarForm.value.sucursalId);
    console.log(this.IniciarForm.value);

    Swal.fire({
      title: "Iniciando Turno ... ",
      text: "",
      timer: 15000,
      showConfirmButton: false,
      allowOutsideClick: false,
      willOpen: () => {
       
        Swal.showLoading()
        
      }
        
    });

    this.turnoServices.iniciarTurno(this.IniciarForm.value).subscribe(response =>{
      if(response.status == 1){
        
        Swal.fire({
          title: "Listo!",
          text: "Turno Iniciado correctamente",
          icon: "success",
          timer: 1500,
        });

        // 
        this.turnoServices.turnoIniciado = true;
        this.router.navigate(['home']);
      
      }
      else{
        Swal.fire({
          title: "Eror!",
          text: response.mensaje,
          icon: "error",
          
        });
        
      }
    });

    

  }

}
