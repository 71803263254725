<div class="container pt-5">
    <p class="text-center titulo_componente">Iniciar Turno</p>
    <div class="pt-4">
        <form [formGroup]="IniciarForm" (ngSubmit)="agregarTurno()">

        <!-- <div class="form-group">
            <label for="EmpleadoID">EmpleadoID</label>
            <input type="text" class="form-control" value="{{Configuracion.empleadoId}}" formControlName="empleadoId" readonly required >
        </div> -->
        <div class="form-group">
            <label for="NombreCompleto">Nombre</label>
            <input type="text" class="form-control" id="Nombre" name="Nombre" readonly value="{{Configuracion.nombreCompleto}}">
        </div>
        <div class="form-group">
            <label for="Sucursal">Sucursal</label>
            <select [(ngModel)]="seleccionado" class="custom-select" formControlName="sucursalId" required>
                <option [value]="sucursal.sucursalId" *ngFor="let sucursal of Configuracion.sucursales"  >{{sucursal.sucursal}}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="MontoInicial">Monto Inicial</label>
            <div class="input-group mb-2 mr-sm-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">$</div>
                </div>
                <input type="number" class="form-control" formControlName="montoInicial" required value="0">
              </div>
            
        </div>
        <div class="pt-3 text-center">
            <button type="submit" class="btn btn-primary" [disabled]="!IniciarForm.valid">Iniciar Turno</button>
        </div>
    </form>
    </div>

    
</div>