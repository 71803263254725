import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfiguracionResponse } from '../models/Response/ConfiguracionResponse';
import Settings from '../settings.json';

@Injectable({
  providedIn: 'root'
})

export class MenuService {
  MenuVisible:boolean;
  Cargando:boolean;
  Movil:boolean;

  constructor(
    private _http:HttpClient
  ) { }

  
  

  
}