import { Component, OnInit } from '@angular/core';
import { TurnosService } from 'src/app/services/Ventas/turnos.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-terminarturno',
  templateUrl: './terminarturno.component.html',
  styleUrls: ['./terminarturno.component.scss']
})
export class TerminarturnoComponent implements OnInit {

  public Configuracion = JSON.parse(localStorage.getItem('usuario')).configuracion;
  public turno;

  public IniciarForm = this.formBuilder.group({
    turnoId: ['', ],
    montoFinal: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder, 
              private turnoServices: TurnosService, 
              private router:Router,
              public menuservices:MenuService) { 

    this.consultarTurno();

    
      
  }

  ngOnInit(): void {
  }



  terminarTurno(){
    
    this.IniciarForm.value.turnoId = this.turno.turnoId;

    console.log(this.IniciarForm.value);

    Swal.fire({
      title: "Terminando turno ... ",
      text: "",
      timer: 15000,
      showConfirmButton: false,
      allowOutsideClick: false,
      willOpen: () => {
       
        Swal.showLoading()
        
      }
        
    });

    this.turnoServices.terminarTurno(this.IniciarForm.value).subscribe(response =>{
      if(response.status == 1){
        
        Swal.fire({
          title: "Listo!",
          text: "Turno Terminado correctamente",
          icon: "success",
          timer: 2000,
        });

        this.turnoServices.turnoIniciado = false;
        this.router.navigate(['home']);
      
      }
      else{
        Swal.fire({
          title: "Eror!",
          text: response.mensaje,
          icon: "error",
          
        });
        
      }
    });

  }

  consultarTurno(){
  
    this.menuservices.Cargando = true;

    this.turnoServices.consultarTurno().subscribe(response =>{
      if(response.status == 1){      
        this.turno = response.data;
        this.turnoServices.turnoIniciado = response.data == null ? false : true;
        console.log(response.data);
        this.menuservices.Cargando = false;
      }
      else{
        Swal.fire({
          title: "Eror!",
          text: response.mensaje,
          icon: "error",
          
        });
        
      }
    });

    

  }

}
