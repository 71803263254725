import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { from } from 'rxjs';
import { SigninComponent } from './components/signin/signin.component';
import { SignupComponent } from './components/signup/signup.component';
import { PrincipalComponent } from './components/principal/principal.component';
import { IniciarturnoComponent } from './components/iniciarturno/iniciarturno.component';

import { TerminarturnoComponent } from './components/terminarturno/terminarturno.component';
import { PuntoVentaComponent } from './components/Ventas/punto-venta/punto-venta.component';
import { AuthGuard } from './securiry/auth.guard';

const routes: Routes = [
 {
    path: '',
    redirectTo: '/signin',
    pathMatch: 'full'
  },
  { path: 'signin', component: SigninComponent},
  { path: 'signup', component: SignupComponent, canActivate: [AuthGuard] },
  { path: 'home', component: PrincipalComponent, canActivate: [AuthGuard] },
  { path: 'iniciarTurno', component: IniciarturnoComponent, canActivate: [AuthGuard]},
  { path: 'terminarTurno', component: TerminarturnoComponent, canActivate: [AuthGuard]},
  { path: 'puntoVenta', component: PuntoVentaComponent, canActivate: [AuthGuard]}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
