<div class="container pt-5">
    <p class="text-center titulo_componente">Punto de Venta</p>
    <div class="pt-4">
        <!-- <form [formGroup]="IniciarForm" (ngSubmit)="agregarTurno()"> -->
        <div>
            
        </div>
        <div class="form-group">
            <label for="NombreCompleto">Nombre</label>
            <input type="text" class="form-control" id="Nombre" name="Nombre" readonly >
        </div>
        <div class="form-group">
            <label for="Sucursal">Sucursal</label>
            <select  class="custom-select" formControlName="sucursalId" required>
                <!-- <option [value]="sucursal.sucursalId"  >{{sucursal.sucursal}}</option> -->
            </select>
        </div>
        <div class="form-group">
            <label for="MontoInicial">Monto Inicial</label>
            <div class="input-group mb-2 mr-sm-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">$</div>
                </div>
                <input type="number" class="form-control" formControlName="montoInicial" required value="0">
              </div>
            
        </div>
        <div class="pt-3 text-center">
            <!-- <button type="submit" class="btn btn-primary" [disabled]="!IniciarForm.valid">Iniciar Turno</button> -->
        </div>
    <!-- </form> -->
    </div>

    
</div>