import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, throwError } from "rxjs";
import { ApiauthService } from "../services/apiauth.service";
import {catchError} from 'rxjs/internal/operators';
import { Router } from "@angular/router";

@Injectable()
export class JwtInterceptor implements HttpInterceptor{

    constructor( private apiauthService: ApiauthService, private router: Router){}
        intercept(request: HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>>{
            const usuario = this.apiauthService.usuarioData;

            if(usuario){
                request = request.clone({
                    setHeaders:{
                        Authorization: `Bearer ${usuario.token}`
                    }
                });
            }

            /*
            request = request.clone({
                setHeaders:{
                    Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImNvYmlhbi5tYWNjQGdtYWlsLmNvbSIsIkNvbmV4aW9uIjoic2VydmVyPTQ2LjE3LjE3NS44NTtwb3J0PTMzMDY7dXNlcj11NTI3NTU4Nzk1X3NxbF91c2VyX2hvbGE7cGFzc3dvcmQ9UEA1NXdvcmQuVXMzcjtkYXRhYmFzZT11NTI3NTU4Nzk1X2hvbGEiLCJFbXByZXNhSUQiOiIxIiwiUGVyZmlsSUQiOiIxIiwiRW1wcmVzYSI6IkhvbGEiLCJVc3VhcmlvSUQiOiIxIiwiRW1wbGVhZG9JRCI6IjQiLCJuYmYiOjE2MTk0MDA5MDMsImV4cCI6MTYxOTQyMjUwMywiaWF0IjoxNjE5NDAwOTAzfQ.3Pf485hv9UdJqaMpFIOcylHWY0-6RvyPhTLsnRLjBzo'
                }
            });
*/
            return next.handle(request).pipe(
                catchError(this.manejarError)
            );
        }

        manejarError(error: HttpErrorResponse){
            console.log(error);
            if(error.status == 401){
                console.log(error.status);
                localStorage.removeItem('usuario');
                this.router.navigate(['/signin']);
            }
            return throwError("");
        }
    
}