<!-- <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <div class="container">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">AdminTive</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarScroll">
        <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ml-auto" style="--bs-scroll-height: 100px;">
          <li class="nav-item">
            <a class="nav-link" routerLink="/signup">SignUp</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav> -->

<app-nav-bar *ngIf="menuservices.Movil && usuario" class="fixed-top" ></app-nav-bar>

<app-menu *ngIf="usuario && (!menuservices.Movil || menuservices.MenuVisible)"></app-menu>

<div [ngClass]="{'area_principal' : usuario, 'area_principal_login': !usuario}">
  <app-cargando *ngIf="menuservices.Cargando"></app-cargando>
  <div class="area_rutting">
    <router-outlet></router-outlet>
  </div>
</div>

