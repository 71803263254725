
<div *ngIf="!menuservices.Cargando" class="container pt-5">
    <p class="text-center titulo_componente">Terminar Turno</p>
    <div class="pt-4">
        <form [formGroup]="IniciarForm" (ngSubmit)="terminarTurno()">

        <!-- <div class="form-group">
            <label for="EmpleadoID">EmpleadoID</label>
            <input type="text" class="form-control" value="{{Configuracion.empleadoId}}" formControlName="empleadoId" readonly required >
        </div> -->
        <div class="form-group">
            <label for="NombreCompleto">Nombre</label>
            <input type="text" class="form-control" id="Nombre" name="Nombre" readonly value="{{Configuracion.nombreCompleto}}">
        </div>
        <div class="form-group">
            <label for="turnoId">TurnoID</label>
            <input type="text" class="form-control" [value]="turno.turnoId" readonly >
        </div>
        <div class="form-group">
            <label for="Sucursal">Sucursal</label>
            <select [value]="turno.sucursalId" class="custom-select" disabled>
                <option [value]="sucursal.sucursalId" *ngFor="let sucursal of Configuracion.sucursales"  >{{sucursal.sucursal}}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="MontoInicial">Monto Inicial</label>
            <div class="input-group mb-2 mr-sm-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">$</div>
                </div>
                <input type="number" class="form-control" matinput [value]="turno.montoInicial" readonly>
              </div>
            
        </div>

        <div class="form-group">
            <label for="TotalEfectivo">Monto en efectivo</label>
            <div class="input-group mb-2 mr-sm-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">$</div>
                </div>
                <input type="number" class="form-control" matinput [value]="turno.totalCaja" readonly>
              </div>
            
        </div>
        <div class="form-group">
            <label for="MontoInicial">Monto en tarjetas</label>
            <div class="input-group mb-2 mr-sm-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">$</div>
                </div>
                <input type="number" class="form-control"  matinput [value]="turno.totalTd" readonly>
              </div>
            
        </div>
        <div class="form-group">
            <label for="MontoInicial">Monto en otros metodos</label>
            <div class="input-group mb-2 mr-sm-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">$</div>
                </div>
                <input type="number" class="form-control"  matinput [value]="turno.totalOtros" readonly>
              </div>
            
        </div>
        <div class="form-group">
            <label for="MontoInicial">Monto Final</label>
            <div class="input-group mb-2 mr-sm-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">$</div>
                </div>
                <input type="number" class="form-control" formControlName="montoFinal" required matinput value="0">
              </div>
            
        </div>
        <div class="pt-3 text-center">
            <button type="submit" class="btn btn-primary" [disabled]="!IniciarForm.valid">Terminar Turno</button>
        </div>
    </form>
    </div>

    
</div>