<!-- <div class="nav-bar">
    <div class="row">
        <div class="col-md-2">
            <i class="iconoMenu"></i>
        </div>
        <div class="">
    
        </div>
    </div>
    
</div> -->


<nav class="navbar navbar-light bg-light" style='background-color: rgb(69, 98, 194);'>
    <a (click)='mostrarMenu()'>
        <i class="fas fa-bars fa-2x"></i>
    </a>
    <a class="navbar-brand" href="#">
      <img class="img-profile" src="assets/img/user.jpg" alt="User picture" width="30" height="30" alt="">
    </a>
  </nav>
